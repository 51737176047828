var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "max-width": "350", right: "", bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g({ staticClass: "pointer" }, on),
                [
                  _c("app-avatar", {
                    attrs: {
                      url: _vm.getFileUrl(_vm.currentUser.avatar),
                      alternative: _vm.currentUser.initials
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-avatar",
                    { attrs: { size: "45" } },
                    [
                      _c("app-avatar", {
                        attrs: {
                          url: _vm.getFileUrl(_vm.currentUser.avatar),
                          alternative: _vm.currentUser.initials
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "font-weight-bold" },
                        [_vm._v(_vm._s(_vm.currentUser.name))]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "font-weight-bold" },
                        [_vm._v(_vm._s(_vm.currentUser.email))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "m-0" }),
          _c(
            "v-list",
            { attrs: { flat: "", dense: "" } },
            [
              _c(
                "v-list-item",
                { staticClass: "mode-switch" },
                [
                  _c("v-switch", {
                    staticClass: "custom-switch mx-0 my-0",
                    attrs: {
                      inset: "",
                      label:
                        _vm.$t("views.apperance") +
                        ": " +
                        (_vm.isDarkMode
                          ? _vm.$t("views.dark")
                          : _vm.$t("views.light"))
                    },
                    on: { change: _vm.toggleDarkMode },
                    model: {
                      value: _vm.isDarkMode,
                      callback: function($$v) {
                        _vm.isDarkMode = $$v
                      },
                      expression: "isDarkMode"
                    }
                  })
                ],
                1
              ),
              _c("v-divider", { staticClass: "m-0" }),
              _vm._l(_vm.accountNavMenu, function(item, index) {
                return [
                  item.divider
                    ? _c("v-divider", {
                        key: index,
                        staticClass: "m-0",
                        attrs: { inset: item.inset }
                      })
                    : _c(
                        "v-list-item",
                        {
                          key: index,
                          attrs: { color: "primary", to: item.link, exact: "" }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t(item.title)))
                          ])
                        ],
                        1
                      )
                ]
              }),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.logout()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-logout-variant")])],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("layout.accountNavMenu.logout")))
                  ])
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }