var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        color: "background",
        app: "",
        "clipped-left": "",
        "clipped-right": "",
        flat: ""
      }
    },
    [
      _c("v-app-bar-nav-icon", { on: { click: _vm.toggleSidebar } }),
      _c(
        "v-toolbar-title",
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("img", {
              attrs: {
                src: _vm.getFileUrl(_vm.company.logo),
                height: "55",
                alt: "logo"
              }
            })
          ])
        ],
        1
      ),
      _c("v-spacer"),
      _c("app-account-nav-menu")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }