var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        right: _vm.rtlLayout,
        "mini-variant": _vm.miniSidebar,
        "expand-on-hover": _vm.miniSidebar,
        dark: _vm.dark,
        app: "",
        clipped: "",
        permanent: ""
      }
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary", mandatory: "" } },
            [
              _vm._l(_vm.menu, function(item) {
                return [
                  !item.items
                    ? [
                        _c("v-list-item", {
                          key: item.title,
                          attrs: { to: item.link, exact: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(item.icon))
                                        ])
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(_vm.$t(item.title)))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    : [
                        _c(
                          "v-list-group",
                          {
                            key: item.title,
                            attrs: { value: true, "no-action": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(item.icon))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$t(item.title)))
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.active,
                              callback: function($$v) {
                                _vm.$set(item, "active", $$v)
                              },
                              expression: "item.active"
                            }
                          },
                          _vm._l(item.items, function(subItem) {
                            return _c(
                              "v-list-item",
                              {
                                key: subItem.title,
                                attrs: { to: subItem.link, exact: "" }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "20" } },
                                          [_vm._v(_vm._s(subItem.icon))]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(subItem.title)) +
                                            " "
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ]
                ]
              }),
              _vm.helpDesks.items.length > 0
                ? [
                    _c(
                      "v-list-group",
                      {
                        key: _vm.helpDesks.title,
                        attrs: { value: true, "no-action": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function() {
                                return [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.helpDesks.icon))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t(_vm.helpDesks.title)))
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1809925235
                        ),
                        model: {
                          value: _vm.helpDesks.active,
                          callback: function($$v) {
                            _vm.$set(_vm.helpDesks, "active", $$v)
                          },
                          expression: "helpDesks.active"
                        }
                      },
                      [
                        _vm._l(_vm.helpDesks.items, function(subItem) {
                          return [
                            subItem.external
                              ? _c("v-list-item", {
                                  key: subItem.title,
                                  attrs: {
                                    href: subItem.link,
                                    target: "_blank"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          return [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(subItem.title))
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              : _c(
                                  "v-list-item",
                                  {
                                    key: subItem.title,
                                    attrs: { to: subItem.link, exact: "" }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "20" } },
                                              [_vm._v(_vm._s(subItem.icon))]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t(subItem.title)) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }