<template>
  <v-menu max-width="350" right bottom>
    <template v-slot:activator="{ on }">
      <div v-on="on" class="pointer">
        <app-avatar :url="getFileUrl(currentUser.avatar)" :alternative="currentUser.initials" />
      </div>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar size="45">
            <app-avatar :url="getFileUrl(currentUser.avatar)" :alternative="currentUser.initials" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ currentUser.name }}</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold">{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="m-0" />
      <v-list flat dense>
        <v-list-item class="mode-switch">
          <v-switch class="custom-switch mx-0 my-0" inset v-model="isDarkMode" @change="toggleDarkMode"
            :label="`${ $t('views.apperance')}: ${ isDarkMode ? $t('views.dark') : $t('views.light') }`"/>
        </v-list-item>
        <v-divider class="m-0" />
        <template v-for="(item, index) in accountNavMenu">
          <v-divider v-if="item.divider" :key="index" :inset="item.inset" class="m-0" />
          <v-list-item v-else color="primary" :key="index" :to="item.link" exact>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('layout.accountNavMenu.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'AccountNavMenu',
  data() {
    return {
      isDarkMode: this.$vuetify.theme.dark
    };
  },
  computed: mapGetters(['currentUser', 'accountNavMenu']),
  methods: {
    ...mapActions(['logout', 'switchDarkMode']),
    toggleDarkMode(value) {
      this.switchDarkMode(value).then(() => {
        this.$vuetify.theme.dark = value;
      });
    }
  }
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
.mode-switch {
  height: 40px;
}
.custom-switch {
  line-height: 40px;
  .v-input__slot {
    margin: 0 !important;
  }
  label {
    font-size: 14px !important;
  }
}
</style>
